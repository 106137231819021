<template>
    <div class="app-content">
        <!-- <div style="margin-bottom:20px">
      <el-card class="box-card">
        <b>
          {{title}}
        </b>
      </el-card>
    </div> -->
        <el-form
            ref="ruleForm"
            :model="form"
            :rules="rules"
            label-width="80px"
            label-position="top"
        >
            <el-row type="flex" justify="center">
                <el-col :sm="20" :lg="16">
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            <b>课程信息</b>
                        </div>
                        <el-row :gutter="24">
                            <el-col :span="8">
                                <el-form-item label="名称" prop="courseName">
                                    <el-input
                                        v-model="form.courseName"
                                        placeholder="请输入"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="是否启用" prop="status">
                                    <el-radio-group v-model="form.status">
                                        <el-radio :label="1">是</el-radio>
                                        <el-radio :label="0">否</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item
                                    label="培训封面"
                                    prop="trainCover"
                                >
                                    <ImageUpload
                                        @input="coverClick"
                                        :value="form.trainCover"
                                        :fileSize="2"
                                        :limit="1"
                                    ></ImageUpload>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item
                                    label="培训课件"
                                    prop="trainCourseware"
                                >
                                    <FileUpload
                                        @input="coursewareClick"
                                        :value="form.trainCourseware"
                                        :fileType="coursewareFileType"
                                        :fileSize="20"
                                        :limit="1"
                                    ></FileUpload>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="培训视频" prop="name">
                                    <videoUpload
                                        @input="videoClick"
                                        :value="form.trainVideo"
                                        :fileType="videoFileType"
                                        :fileSize="2048"
                                        :limit="1"
                                    ></videoUpload>
                                </el-form-item>
                            </el-col>

                            <el-col :span="24">
                                <el-form-item label="简介" prop="introduction">
                                    <el-input
                                        type="textarea"
                                        :rows="2"
                                        v-model="form.introduction"
                                        placeholder="请输入"
                                    />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-card>
                    <el-card class="box-card" style="margin-top: 10px">
                        <div slot="header" class="clearfix">
                            <b>培训内容</b>
                        </div>
                        <editor v-model="form.trainContent" :min-height="192" />
                    </el-card>
                    <el-card
                        class="box-card bottom-card"
                        style="margin-top: 10px"
                    >
                        <el-row type="flex" justify="end">
                            <!-- <el-button @click="resetForm('ruleForm')">取 消</el-button> -->
                            <el-button
                                type="primary"
                                @click="submitForm('ruleForm')"
                                >提 交</el-button
                            >
                        </el-row>
                    </el-card>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import {
    getTrainCourseInfoApi,
    getTrainCourseCreateApi,
    getTrainCourseUpdateApi,
} from '@/api/training'
export default {
    data() {
        return {
            fileList: [],
            coursewareFileType: ['pdf'],
            videoFileType: ['mp4'],
            companyTypeList: [
                {
                    label: '模板1',
                    value: '模板1',
                },
                {
                    label: '模板2',
                    value: '模板2',
                },
            ],
            businessTypeList: [
                {
                    label: '是',
                    value: '是',
                },
                {
                    label: '否',
                    value: '否',
                },
            ],
            title: '新增模板',
            form: {},
            rules: {
                courseName: [
                    {
                        required: true,
                        message: '请输入课程名称',
                        trigger: 'blur',
                    },
                ],
                status: [
                    {
                        required: true,
                        message: '请选择是否启用',
                        trigger: 'blur',
                    },
                ],
            },
        }
    },
    created() {
        if (this.$route.query.id) {
            this.getTrainCourseInfoFun()
        }
    },
    methods: {
        // 获取详情
        async getTrainCourseInfoFun() {
            var params = {
                id: this.$route.query.id,
            }
            var res = await getTrainCourseInfoApi(params)
            this.form = res
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.$route.query.id) {
                        this.getTrainCourseUpdateFun()
                    } else {
                        this.getTrainCourseCreateFun()
                    }
                } else {
                    this.$message({
                        type: 'error',
                        message: '请检查是否有提示选项'
                    })
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // 新增
        async getTrainCourseCreateFun() {
            var res = await getTrainCourseCreateApi(this.form)
            console.log('新咋', res)
            if (res.code === 200) {
                this.$message({
                    message: '新增成功',
                    type: 'success',
                })
                this.$router.go(-1)
            } else {
                this.$message({
                    message: '新增失败',
                    type: 'error',
                })
            }
        },
        // 更新
        async getTrainCourseUpdateFun() {
            delete this.form.createTime
            delete this.form.publishTime
            var res = await getTrainCourseUpdateApi(this.form)
            console.log('更新', res)
            if (res.code === 200) {
                this.$message({
                    message: '更新成功',
                    type: 'success',
                })
                this.$router.go(-1)
            } else {
                this.$message({
                    message: '更新失败',
                    type: 'error',
                })
            }
        },
        // 培训封面
        coverClick(value) {
            this.form.trainCover = value
        },
        // 培训视频
        videoClick(value) {
            this.form.trainVideo = value
        },
        // 培训课件
        coursewareClick(value) {
            this.form.trainCourseware = value
        },
    },
}
</script>

<style scoped>
.app-content {
    width: 1200px;
    margin: 20px auto 20px auto;
    /* margin-top: 20px; */
}
::v-deep .el-form-item {
    width: 100%;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}
</style>
